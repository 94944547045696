"use strict";

(function () {
  "use strict";

  config.$inject = ["$stateProvider"];
  angular.module("vehicles").config(config);

  function config($stateProvider) {
    $stateProvider.state("vehicles", {
      url: "/vehicles",
      templateUrl: "app/pages/vehicles/vehicles.html",
      controller: "VehiclesCtrl",
      controllerAs: "vm",
      title: "Vehicles",
      resolve: {
        ready: function ready($q, $rootScope) {
          var deferred = $q.defer();
          if ($rootScope.$user) {
            deferred.resolve("");
          } else {
            $rootScope.$watch(function () {
              return $rootScope.$user;
            }, function () {
              if ($rootScope.$user) {
                deferred.resolve("");
              }
            }, true);
          }
          return deferred.promise;
        }
      }
    });
  }
})();