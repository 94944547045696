"use strict";

angular.module("my-formly").service("FormlyService", [function () {
  var that = this;

  that.log = debug("service:formly");

  that.list = function (key, label, options) {
    var required = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var objectToMerge = arguments[4];
    var tooltip = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "";

    return _.merge({
      key: key,
      id: key,
      type: "nya-bs-select",

      templateOptions: {
        label: label,
        options: options,
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.info = function (label, value) {
    return {
      template: "<div class=\"form-group\"><label>" + label + "</label><div class=\"flexbox form-control\">" + value + "</div></div>"
    };
  };

  that.text = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    return _.merge({
      key: key,
      id: key,
      type: "input",

      templateOptions: {
        label: label,
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.number = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    return _.merge({
      key: key,
      id: key,
      type: "input",

      templateOptions: {
        label: label,
        type: "number",
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.currency = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    // return _.merge({
    //     key: key,
    //     id: key,
    //     type: 'input',

    //     templateOptions: {
    //         label: label,
    //         type: 'currency',
    //         required: required
    //     }
    // }, objectToMerge)

    return _.merge({
      key: key,
      id: key,
      type: "currency",

      templateOptions: {
        label: label,
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.date = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    return _.merge({
      key: key,
      id: key,
      //type: 'datepicker',
      type: "bootstrap-date-picker",

      templateOptions: {
        label: label,
        type: "text",
        datepickerPopup: "dd-MMMM-yyyy",
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.time = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    return _.merge({
      key: key,
      id: key,
      //type: 'datepicker',
      type: "bootstrap-time-picker",

      templateOptions: {
        label: label,
        type: "text",
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.bool = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    return _.merge({
      key: key,
      id: key,
      type: "bs-switch",

      templateOptions: {
        label: label,
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.boolOld = function (key, label) {
    var required = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var objectToMerge = arguments[3];
    var tooltip = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "";

    return _.merge({
      key: key,
      id: key,
      type: "no-bs-switch",

      templateOptions: {
        label: label,
        required: required,
        tooltip: tooltip
      }
    }, objectToMerge);
  };

  that.insurerDocs = function (key, label, objectToMerge) {
    return _.merge({
      key: key,
      id: key,
      type: "select-insurer-docs",
      templateOptions: {
        label: label,
        type: "text"
      }
    }, objectToMerge);
  };

  that.rrule = function (key, objectToMerge) {
    return _.merge({
      key: key,
      id: key,
      type: "rrule"
    }, objectToMerge);
  };

  that.summernote = function (key, objectToMerge) {
    return _.merge({
      key: key,
      id: key,
      type: "summernote"
    }, objectToMerge);
  };

  that.doc = function (key, label, type) {
    var required = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var objectToMerge = arguments[4];
    var tooltip = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "";
    var isRestricted = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : true;

    return _.merge({
      type: "edit-docs",
      id: key,
      key: key,
      templateOptions: {
        label: label,
        type: type,
        required: required,
        tooltip: tooltip,
        isRestricted: isRestricted
      }
    }, objectToMerge);
  };

  that.textArea = function (key, label, placeholder) {
    var required = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var objectToMerge = arguments[4];
    var tooltip = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : "";

    return _.merge({
      type: "textarea",
      key: key,
      id: key,
      templateOptions: {
        placeholder: placeholder,
        required: required,
        tooltip: tooltip,
        label: label,
        rows: 5,
        cols: 15
      }
    }, objectToMerge);
  };

  that.line = function (fields) {
    return {
      className: "row",
      fieldGroup: fields
    };
  };

  that.button = function (label, onClick, objectToMerge) {
    return _.merge({
      type: "button",
      templateOptions: {
        label: label,
        onClick: onClick
      }
    }, objectToMerge);
  };
}]);