"use strict";

/*global angular*/

(function () {
  "use strict";

  angular.module("pages").run(function ($rootScope, $location, ActionHistoriesService, LbService) {
    var log = debug("pages:run");

    log("run pages");

    $rootScope.$on("$locationChangeStart", function (event, next, current) {
      // handle route changes

      log("change route", $location.url());

      if ($location.url() != "/login") {
        localStorage.setItem("$location.url", $location.url());
      }

      if ($rootScope.$user && $rootScope.$user.id) {
        ActionHistoriesService.create({
          customerId: $rootScope.$user.customerId,
          userId: "$" + $rootScope.$user.id,
          action: $location.url(),
          date: new Date()
        });
      }
    });

    function getSearchParameters() {
      var prmstr = window.location.search.substr(1);
      return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
    }

    function transformToAssocArray(prmstr) {
      var params = {};
      var prmarr = prmstr.split("&");
      for (var i = 0; i < prmarr.length; i++) {
        var tmparr = prmarr[i].split("=");
        params[tmparr[0]] = tmparr[1];
      }
      return params;
    }

    var params = getSearchParameters();

    log("params", params);

    if (params.token) {
      localStorage.setItem("$LoopBack$accessTokenId", params.token);

      localStorage.setItem("$ChangePassword", "true");

      LbService.setToken(params.token);

      var url = document.location.href;
      window.location.replace(url.split("?")[0] + "#!/home");
    }

    setTimeout(function () {
      $("#loading").remove();
    }, 1500);
  });
})();